import './form.scss';
import axios from 'axios';
import { createElementFromHTML } from '../../utilities/js/helper';
class Form {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-contact-overlay',
            onSubmit: null,
            loader: null
        };

        this.settings = Object.assign({}, defaults, options);

        this.$contactOverlay = element;
        this.initialize();
    }

    initialize () {
        this.setEvents();
        this.initForm();
    }

    setEvents () {
        /* @todo: remove setTimeout and getting slim select */
        setTimeout(() => {
            this.showContact(document.getElementById('contact_form_type').slim.getSelected()[0]);
            document.getElementById('contact_form_type').addEventListener('change', () => {
                this.showContact(document.getElementById('contact_form_type').slim.getSelected()[0]);
            });
        }, 5);
    }

    showContact (selectedValue) {
        const contactPerson = this.$contactOverlay.querySelectorAll('[data-contact-person]');
        this.$contactOverlay.classList.remove('form__overlay--has-person');
        for (let i = 0; i < contactPerson.length; i++) {
            if (selectedValue === contactPerson[i].getAttribute('data-contact-person-value')) {
                contactPerson[i].classList.remove('form__contact-person--is-hidden');
                this.$contactOverlay.classList.add('form__overlay--has-person');
            } else {
                contactPerson[i].classList.add('form__contact-person--is-hidden');
            }
        }
    }

    initForm () {
        const $form = this.$contactOverlay.querySelector('form');
        const $button = $form.querySelector('[type="submit"]');

        $form.addEventListener('submit', (e) => {
            const formMethod = $form.getAttribute('method');
            const formAction = $form.getAttribute('action');
            const formData = new FormData($form);

            let $loader = null;

            if (this.settings.loader !== null) {
                $loader = createElementFromHTML(this.settings.loader);
            }

            if ($loader !== null) {
                $button.appendChild($loader);
                $button.setAttribute('disabled', true);
            }

            axios({
                method: formMethod,
                url: formAction,
                data: formData
            })
                .then((response) => {
                    if (typeof response.data.action !== 'undefined' && typeof response.data.content !== 'undefined') {
                        console.log(response.data.action);
                        if (response.data.action === 'render') {
                            const content = response.data.content;
                            let $content = createElementFromHTML(content);
                            const $contactOverlayContent = this.$contactOverlay.closest('[data-contact-overlay="content"]');
                            if ($contactOverlayContent !== null) {
                                $content = $content.querySelector('[data-contact-overlay="content"]');
                                $contactOverlayContent.innerHTML = $content.innerHTML;
                                this.$contactOverlay = $contactOverlayContent;
                                this.setEvents();
                            } else {
                                this.$contactOverlay.innerHTML = $content.innerHTML;
                                this.initForm();
                                this.setEvents();
                            }

                            if (typeof this.settings.onSubmit === 'function') {
                                this.settings.onSubmit(this.$contactOverlay);
                            }
                        }

                        if (response.data.action === 'success') {
                            const content = response.data.content;
                            let $content = createElementFromHTML(content);

                            const $contactOverlayContent = $content.querySelector('[data-contact-overlay="content"]');
                            if ($contactOverlayContent !== null) {
                                $content = content.querySelector('[data-contact-overlay="content"]');
                                $contactOverlayContent.innerHTML = $content.innerHTML;
                                this.$contactOverlay = $contactOverlayContent;
                            } else {
                                this.$contactOverlay.innerHTML = $content.innerHTML;
                                this.initForm();
                            }

                            if (typeof this.settings.onSubmit === 'function') {
                                this.settings.onSubmit(this.$contactOverlay);
                            }
                        }
                    }
                })
                .catch((error) => {
                    throw Error(error);
                });

            e.preventDefault();
        });
    }
}

export { Form };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $contactOverlay = $context.querySelectorAll('[data-contact-overlay="root"]');

        if ($contactOverlay.length > 0) {
            for (let i = 0; i < $contactOverlay.length; i++) {
                const contactOverlay = $contactOverlay[i];
                contactOverlay.API = new Form(contactOverlay, {
                    onSubmit: ($content) => {
                        const contentUpdateEvent = new CustomEvent('content.loaded', {
                            view: window,
                            bubbles: true,
                            cancelable: false,
                            detail: {
                                $context: $content
                            }
                        });
                        window.dispatchEvent(contentUpdateEvent);
                    }
                });
            }
        }
    }
});
